import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    BROCHURES: "Brochures",
    CATALOGS: "Catalogs",
    FLYERS: "Flyers",
    EBOOKS: "Apps",
    WEB: "Web",
    SOCIAL: "Social",

  };

  const projectsData = [
    {
      title: "Brochure-1",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage:         "images/projects/brochures/brochure_1.jpg",
      // sliderImages: [
      //   "images/projects/brochures/brochure_1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.BROCHURES],
    },
    {
      title: "Brochure-2",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Brochures/Brochure-2.jpg",
      // sliderImages: [
      //   "images/projects/project-2.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.BROCHURES],
    },
    {
      title: "Brochures-3",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Brochures/Brochure-3.jpg",
      // sliderImages: [
      //   "images/projects/project-3.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.BROCHURES],
    },
    {
      title: "Brochure-4",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Brochures/Brochure-4.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-4.jpg",
      // ],
      categories: [filters.BROCHURES],
    },
    {
      title: "Brochure-5",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Brochures/Brochure-5.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.BROCHURES],
    },
    {
      title: "Brochure-6",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Brochures/Brochure-6.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.BROCHURES],
    },
    {
      title: "Brochure-7",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Brochures/Brochure-7.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.BROCHURES],
    },
    {
      title: "Altitude Catalog Design",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/Altitude_Catalog Design.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "Aurora Promotion Catalog",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/Aurora-Promotion-Catalog.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "Clock Catalog",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/Clock_Catalog.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "Factory Product Catalog",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/Factory Product_Catalog.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "Industrial Catalog",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/Industrial-Catalog.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "Jewellery Catalog",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/Jewelry_catalog.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "Krysaliis Catalog",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/Krysaliis_Catalog.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "Medical Instruments Catalog",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/Medical Instruments_Catalog.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "Owen Kitchen Design Catalog",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/Owen Kitchen_Catalog Design.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "Soller Catalog",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/Soller_Catalog.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "Tools Catalog Design",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/Tools_Catalog Design.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "VMax Catalog",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/VMax_Catalog.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "Wine Catalog",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Catalogs/Wine_Catalog.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.CATALOGS],
    },
    {
      title: "Flyer-1",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Flyer/Flyer-1.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.FLYERS],
    },
    {
      title: "Flyer-2",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Flyer/Flyer-2.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.FLYERS],
    },
    {
      title: "Flyer-3",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Flyer/Flyer-3.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.FLYERS],
    },
    {
      title: "Flyer-4",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Flyer/Flyer-4.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.FLYERS],
    },
    {
      title: "Flyer-5",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Flyer/Flyer-5.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.FLYERS],
    },
    {
      title: "Flyer-6",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Flyer/Flyer-6.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.FLYERS],
    },
    {
      title: "Flyer-7",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Flyer/Flyer-7.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.FLYERS],
    },
    {
      title: "Flyer-8",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Flyer/Flyer-8.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.FLYERS],
    },
    {
      title: "Flyer-9",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Flyer/Flyer-9.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.FLYERS],
    },
    {
      title: "Flyer-10",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Flyer/Flyer-10.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.FLYERS],
    },
    {
      title: "App-1",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/AppPortfolio/App-1.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.EBOOKS],
    },
    {
      title: "App-2",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/AppPortfolio/App-2.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.EBOOKS],
    },
    {
      title: "App-3",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/AppPortfolio/App-3.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.EBOOKS],
    },
    {
      title: "App-4",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/AppPortfolio/App-4.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.EBOOKS],
    },
    {
      title: "App-5",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/AppPortfolio/App-5.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.EBOOKS],
    },
    {
      title: "App-6",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/AppPortfolio/App-6.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.EBOOKS],
    },
    {
      title: "App-7",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",

      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/AppPortfolio/App-7.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.EBOOKS],
    },
    {
      title: "Beep Website Design",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
        
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Web/Beep_Website Design.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.WEB],
    },
    {
      title: "Fitness Contact Page",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
        
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Web/Fitness_Contact Page.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.WEB],
    },
    {
      title: "Fitness Website",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
        
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Web/Fitness_Website.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.WEB],
    },
    {
      title: "Homepage Design",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
        
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Web/Home-page3.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.WEB],
    },
    {
      title: "Mobileapptek Website Design",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
        
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Web/Mobileapptek_Web Site Design.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.WEB],
    },
    {
      title: "Project Karma Website Copy ",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
        
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Web/Project Karma_ Web Site copy.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.WEB],
    },
    {
      title: "Project Karma Website",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
        
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Web/Project Karma_ Web Site.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.WEB],
    },
    {
      title: "Site",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
        
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Web/Site.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.WEB],
    },
    {
      title: "Solar",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
        
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Web/Solar.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.WEB],
    },
    {
      title: "Facebook Covers",
      // projectInfo:
      //   "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      // client: "Ruby Clinton",
      // technologies: "iOS, HTML5, CSS3, PHP, Java",
      // industry: "Art & Design",
      // date: "July 16, 2019",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
        
      // },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "mailto:example@gmail.com",
      // },
      thumbImage: "images/projects/Social/Facebook-Covers.jpg",
      // sliderImages: [
      //   "images/projects/project-1.jpg",
      //   "images/projects/project-5.jpg",
      // ],
      categories: [filters.SOCIAL],
    },
    
    
    
    
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
